import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageTitle from '../components/page-title';

const About = () => {
  return (
    <Layout>
      <SEO title="About" />
      <PageTitle>🧑 About Me</PageTitle>
      <p>
        My name is Josiah. I'm a Senior Software Engineer at a mid-size
        ecommerce company. I love writing code and my main interest is backend
        web development so I will mostly write about that.
      </p>
      <p>
        I believe in constant learning and spreading that knowledge around. If
        anyone ends up reading this and happens to learn something new, it will
        all have been worth it.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          alt="Farewell gif"
          src={'https://media.giphy.com/media/kaBU6pgv0OsPHz2yxy/giphy.gif'}
        />
      </div>
    </Layout>
  );
};

export default About;
